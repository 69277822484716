<template>
  <layout  width="540">
    <template #header>
      Edit {{ properties[0].groupName.toLowerCase() }}
    </template>
    <div style="height: 100%;">
      <template v-for="item in propertiesLocal">
        <template v-if="!item.valueSet && !item.valueRange">
          <v-select
            v-if="isBoolean(item.typeId)"
            :key="item.id"
            v-model="item.value"
            :label="`${item.humanReadable}`"
            item-value="id"
            item-text="name"
            :items="[
              { id: true, name: 'true' },
              { id: false, name: 'false' },
              { id: null, name: 'null' }
            ]"
            class="pb-4"
          />
          <code-editor
            v-else-if="isJson(item.typeId)"
            :key="item.id"
            v-model="item.value"
            :code="item.value"
            @is-error="isCodeError = $event"
          />
          <template v-else>
            <v-text-field
              v-if="isNumber(item.typeId)"
              :key="item.id"
              v-model.number="item.value"
              type="number"
              :label="item.humanReadable"
              clearable
              :placeholder="item.property"
            />
            <v-text-field
              v-else
              :key="item.id"
              v-model="item.value"
              :label="item.humanReadable"
              clearable
              :placeholder="item.property"
            />
          </template>
        </template>
        <template v-else>
          <v-select
            v-if="item.valueSet && item.valueSet.component === 'select'"
            :key="item.id"
            v-model="item.value"
            item-value="key"
            class="pb-4"
            item-text="title"
            :items="item.valueSet.list"
          />
          <v-text-field
            v-else-if="item.valueSet"
            :key="item.id"
            v-model="item.value"
            class="pb-4"
          />
          <v-text-field
            v-if="item.valueRange"
            :key="item.id"
            v-model.number="item.value"
            class="pb-4"
            :max="item.valueRange.max"
            :min="item.valueRange.min"
            type="number"
          />
        </template>
      </template>
    </div>
    <template #footer>
      <v-spacer />
      <v-btn text @click="$emit('close')">Cancel</v-btn>
      <v-btn
        color="primary"
        text
        :loading="loading"
        :disabled="loading"
        @click="saveHandler"
      >
        save
      </v-btn>
    </template>
  </layout>
</template>

<script>
import { defineComponent, onMounted, ref } from '@vue/composition-api';

import sortBy from 'lodash.sortby';
import cloneDeep from 'lodash.clonedeep';
import isEqual from 'lodash.isequal';

import Layout from './PopupLayoutDefault';

import CodeEditor from '@/components/_base/CodeEditor';

import { isBoolean, isJson, isNumber } from '@/utils/getCurrentType';

export default defineComponent({
  name: 'EditProperties',
  components: { Layout, CodeEditor },
  props: {
    properties: {
      type: Array,
      required: true
    },
    object: {
      type: Object,
      required: true
    },
    objectId: {
      type: String,
      required: true
    },
    onConfirm: {
      type: Function,
      default: () => {}
    }
  },
  setup(props, { emit }) {
    const propertiesLocal = ref();
    const loading = ref(false);

    const close = () => {
      emit('close');
    };

    const saveHandler = async () => {
      let cloneProperties = cloneDeep(propertiesLocal.value);
      let changedProperties = [];

      cloneProperties.forEach(item => {
        let isChanged = props.properties.find(
          itemInner => itemInner.id === item.id
        ).value;

        if (!isEqual(item.value, isChanged)) {
          changedProperties.push(item);
        }
      });

      if (changedProperties.length) {
        let data = changedProperties.map(item => ({
          value: item.value,
          id: item.id
        }));

        try {
          loading.value = true;
          await props.onConfirm(data);
          loading.value = false;
          emit('close');
        } finally {
          loading.value = false;
        }
      } else {
        close();
      }
    };

    onMounted(() => {
      propertiesLocal.value = sortBy(
        cloneDeep(props.properties),
        'humanReadable'
      );
    });

    return {
      propertiesLocal,
      saveHandler,
      isJson,
      isBoolean,
      isNumber,
      loading
    };
  }
});
</script>
